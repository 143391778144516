@font-face {
    font-family: "Cereal";
    src: url("./fonts/S-Core - Core Circus.otf") format("opentype");
}

@font-face {
    font-family: "Rational";
    src:url("./fonts/RationalTextBook/font.woff") format('woff');
}

body {
    font-family: Cereal;
    background-color: #faf9f4;
}

form {
    padding: 20px 10px;
    border: 2px solid #e5e4ca;
}

a {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

input{
    font-family: Rational;
}

button svg path {
    stroke-width: 4px;
}

/*div#root a{
    color: #35D4F8;
}*/

.privacy-wrapper a{
    color: #35D4F8;
}


li {
    list-style-type: none;
}

/*.izBGIK{
    padding: 0px !important;
}*/